<template>
  <!--<portal to="sidebar">
    <h3 class="sidebar__nav-heading">Jump to</h3>
    <nav class="sidebar__nav">
      <a href="#">Project Update</a>
      <a href="#">Project Contacts</a>
      <a href="#">Milestones</a>
    </nav>
  </portal>-->
  <promise invoke="getJob" :args="{ id: $route.params.id }" :key="$route.params.id" @done="ready" @ready="updateTitle">
    <template #content="props">
      <header class="main-header">
        <div class="row align-items-center">
          <div class="col-10">
            <div class="row no-gutters align-items-center">
              <div class="col-auto">
                <Avatar :string="props.data.client.name" class="userinfo__image-container--xl"/>
              </div>
              <div class="col-auto">
                <h1 class="main-header__title"><span>{{ props.data.jobRef }}</span> &mdash; {{ props.data.jobName }}</h1>
                <h2 class="main-header__subtitle">{{ props.data.client.name }}</h2>
              </div>
            </div>
          </div>
          <div class="col-2 text-right">
            <small class="client-project__details">
              <span class="d-block status-bubble status-bubble--green">{{ props.data.state }}</span>
              <!--<span class="d-block client-project__due-date mt-1"><strong>Due:</strong> --/--/--</span>-->
            </small>
          </div>
        </div>
      </header>
      <section class="main-content">
        <div v-if="props.data.projectUpdate" class="mb-5">
          <!-- project update -->
          <h3 class="mt-0 mb-0">Project Update</h3>
          <p class="heading-desc mt-0 mb-3">Last updated on <strong>{{ formatDate(props.data.projectUpdate_updated_at) }}</strong></p>
          <div class="project__update" v-html="props.data.projectUpdate"/>
        </div>
        <div class="row mt-5">
          <div class="col-lg-6 mb-5 mb-lg-0">
            <!-- documents -->
            <h3 class="mt-0 mb-3">Project Documents</h3>
            <table class="table table--sm table--align-left">
              <thead>
                <tr>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="document in props.data.projectDocuments" :key="document.id">
                  <td>
                    <a :href="document.url" target="_blank" rel="noopener" class="link__link">
                      <span class="link__title">{{ document.title }}</span>
                      <span class="link__url">{{ document.document_type === 'file' ? document.original_filename : document.url }}</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6">
            <!-- project contacts -->
            <h3 class="mt-0">Project Contacts</h3>
            <div class="row">
              <div class="col-6">
                <user-card :contact="{ name: 'John Smith', position: 'Account Manager', email: 'hello@welovechatter.com', mobile: '01135240390' }"/>
              </div>
              <div class="col-6">
                <user-card :contact="{ name: 'Jane Doe', position: 'Job Manager', email: 'hello@welovechatter.com', mobile: '01135240390' }"/>
              </div>
            </div>
          </div>
        </div>
        <!-- milestones -->
        <!-- MODAL : amend milestone document -->
        <ajax-modal v-if="amendDocumentContext" ref="amendMilestoneDocument" id="amend-milestone-document" :url="`/job/${$route.params.id}/milestone/${amendDocumentContext.milestone.id}/document/${amendDocumentContext.document.document_uuid}`" @done="updateMilestoneDocument">
          <template #title>Amend Milestone Document</template>
          <template #fields>
            <p class="mt-0">Please describe what you'd like us to change for the milestone document named <strong>{{ amendDocumentContext.document.title }}</strong>.</p>
            <input type="hidden" name="signoff_status" value="amends-required" />
            <div class="form-group mt-3 mb-2">
              <label for="amend_message">Message <abbr class="required">*</abbr></label>
              <textarea name="signoff_client_comments" id="amend_message" ref="amendMessage" class="form-control" placeholder="Message" rows="4" required></textarea>
            </div>
          </template>
        </ajax-modal>
        <h3 class="mt-5">Project Milestones</h3>
        <div class="project__milestones" :class="{ 'project__milestones--no-side': props.data.milestones.length === 0 }">
          <article v-for="milestone in descendingOrder(props.data.milestones)" :key="milestone.id" class="project__milestones__milestone" :class="{ 'project__milestones__milestone--complete': milestone.completed }">
            <div class="row">
              <div class="col-6">
                <h3 class="project__milestones__milestone__title">{{ milestone.title }}</h3>
                <h4 class="project__milestones__milestone__subtitle">
                  <span v-if="milestone.completed" class="status-bubble status-bubble--sm status-bubble--green">Complete on {{ formatDate(milestone.completed_at) }}</span>
                  <span v-else><strong>Due</strong> {{ formatDate(milestone.due_date) }}</span>
                </h4>
                <p>{{ milestone.description }}</p>
              </div>
              <div class="col-6">
                <h3 class="mt-0 mb-3">Attached Documents</h3>
                <div class="project__milestones__milestone__documents-container">
                  <div v-for="document in milestone.documents" :key="document.document_uuid" class="project__milestones__milestone__document" :data-uuid="document.document_uuid">
                    <a :href="document.url" target="_blank" rel="noopener">
                      {{ document.title }}
                      <span v-if="document.requires_signoff" class="status-bubble status-bubble--xs"
                            :class="milestoneDocumentStates[document.signoff_status].class">
                        {{ milestoneDocumentStates[document.signoff_status].label.client }}
                      </span>
                    </a>
                    <div class="heading-desc">
                      <div v-if="document.chatter_document_message" class="mt-1 mb-0" v-html="document.chatter_document_message"/>
                      <div v-if="document.signoff_status === 'amends-required' && document.signoff_client_comments" class="heading-desc mt-1 mb-0">
                        <strong>Requested amends</strong>: <div v-html="document.signoff_client_comments"/>
                      </div>
                    </div>
                    <div v-if="document.requires_signoff && document.signoff_status === 'awaiting'" class="mt-2">
                      <button class="button button--sm mr-1" @click="acceptMilestoneDocument(milestone, document)">Accept</button>
                      <button class="button button--sm button--grey" @click="openAmendMilestoneDocumentModal(milestone, document)">Request Amend</button>
                    </div>
                  </div>

                  <!-- empty -->
                  <div v-if="milestone.documents.length === 0" class="project__milestones__milestone__document table__empty">
                    <p>No documents have been added.</p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <!-- no milestones -->
          <article v-if="props.data.milestones.length === 0" class="project__milestones__milestone project__milestones__milestone--empty">
            <p>No milestones have been added.</p>
          </article>
        </div>
      </section>
    </template>
  </promise>
</template>

<script>
import { ref, computed } from 'vue'
import { useMeta } from 'vue-meta'
import Promise from '@/components/promise.vue'
import UserCard from '@/components/usercard.vue'
import Avatar from '@/components/avatar.vue'
import AjaxModal from '@/components/ajax-modal.vue'
import { formatDate } from '@/utils'
import { milestoneDocumentStates } from '@/strings'

export default {
  components: {
    Promise,
    UserCard,
    Avatar,
    AjaxModal,
  },
  setup() {
    // TODO : use metaInfo() once https://github.com/nuxt/vue-meta/issues/699 is resolved.
    const title = ref('');
    const updateTitle = (data) => title.value = `${data.jobRef} - ${data.client.name}`;
    useMeta(computed(() => ({ title: title.value })));

    return { updateTitle };
  },
  data() {
    return {
      milestoneDocumentStates,

      // context for amend milestone document modal
      amendDocumentContext: null,
    };
  },
  methods: {
    formatDate,
    ready() {
      const { document_uuid } = this.$route.query;
      if (document_uuid) {
        this.$nextTick(() => {
          const el = document.querySelector(`[data-uuid="${document_uuid}"]`);
          if (el) {
            el.scrollIntoView({ block: 'center' });
            el.classList.add('highlight-element');
          }
        });
      }
    },
    /**
     * Order milestones in descending order based on their due date
     */
    descendingOrder(milestones) {
      milestones.sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
      return milestones;
    },
    /**
     * Helper function to set context and open the amend milestone document modal
     */
    openAmendMilestoneDocumentModal(milestone, document) {
      this.amendDocumentContext = { milestone, document };
      this.$nextTick(() => this.openModal('amend-milestone-document'));
    },
    /**
     * Ajax Form response callback after updating a milestone document
     */
    updateMilestoneDocument({ formData }) {
      const { document } = this.amendDocumentContext;

      // update document object
      document.signoff_status = formData.get('signoff_status');
      document.signoff_client_comments = formData.get('signoff_client_comments');

      // TODO : ideally we'd reset this here, but the modal will unmount if we do it here (because we're using v-if)
      //        and the closing animation won't play which looks a bit rubbish.
      // this.amendDocumentContext = null;
    },
    /**
     * Helper function to Accept milestone documents.
     * We set the amendDocumentContext then nextTick call the AjaxModal submit method.
     *
     * NOTE : We wait for nextTick as we're using v-if to mount the modal after the context
     *        is set meaning it won't be available right away!
     */
    acceptMilestoneDocument(milestone, document) {
      this.amendDocumentContext = { milestone, document };

      this.$nextTick(() => {
        const formData = new FormData();
        formData.append('signoff_status', 'approved');
        this.$refs.amendMilestoneDocument.submit(formData);
      });
    },
  },
}
</script>